import { Fragment } from "react";
import SocialBar from "../components/social-bar";
import SectionLink from "../components/section-link";
import News from "../components/news";
import { useHome } from "../hooks/useQueryCustom";
import Header from "../components/header";
import { useNavigazione } from "../context/navigazione";
import classNames from "classnames";
import { NOFESTIVAL_MODE, NOFESTIVALAR_MODE } from "../utils/constants";
import Seo from "../components/seo";

const Home = () => {
  const { mode, imgEdizione, linkVideo, bannerOrientamento } = useNavigazione();

  const { data: home } = useHome();
  const fasce = home ? home.articoliCollection.items : [];

  const nofestival = mode === NOFESTIVAL_MODE || mode === NOFESTIVALAR_MODE;
  return (
    <>
      <Seo
        sitename={false}
        title="Festival della Scienza"
        description={home?.metaDescription}
      />

      <Header isHome={true} />

      <div role="main" className="main-wrapper" id="main">
        <SocialBar />
        <div className="fullvideo d-print-none d-none d-md-block">
          <div className="fullvideo__background">
            <div className="fullvideo__foreground">
              {mode !== nofestival && imgEdizione && (
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII="
                  />
                  <source
                    media="(min-width: 769px)"
                    srcSet={`${imgEdizione}&w=1920`}
                  />
                  <img
                    src={`${imgEdizione}&w=1920`}
                    alt="Immagine edizione Festival"
                  />
                </picture>
              )}
              {nofestival && linkVideo && (
                <iframe
                  allowFullScreen="allowfullscreen"
                  src={`${linkVideo}?controls=0&showinfo=0&rel=0&autoplay=1&mute=1&loop=1`}
                  enablejsapi="true"
                  id="widget2"
                  title="Youtube"
                >
                  <p>Il tuo browser non supporta i frame</p>
                </iframe>
              )}
            </div>
          </div>
        </div>
        <div
          className={classNames(
            "d-block d-md-none mb-4",
            !nofestival && "pt-5 pt-md-0"
          )}
        >
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={`${imgEdizione}&w=800`}
            />
            <source
              media="(min-width: 769px)"
              srcSet="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII="
            />
            <img
              src={`${imgEdizione}&w=800`}
              alt="Immagine edizione Festival"
            />
          </picture>
        </div>
        {fasce?.map((fascia, index) => (
          <Fragment key={index}>
            {fascia.layout === "Card" && fascia.articoliCollection?.items && (
              <News data={fascia} />
            )}
            {fascia.layout === "Link due righe" &&
              fascia.articoliCollection?.items && <SectionLink data={fascia} />}
          </Fragment>
        ))}
        {bannerOrientamento && (
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <a
                  href="http://www.orientamenti.regione.liguria.it/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="val al sito orientamenti liguria"
                >
                  <img
                    alt="Orientamenti"
                    title="Orientamenti"
                    className="orientamento"
                    src={bannerOrientamento}
                  />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Home;
